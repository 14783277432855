<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Tables from "@/components/elements/Tables.vue";
import swal from "sweetalert2";
import {
  GetAllPrices,
  EditPrice,
  AddPrice,
  DeletePrice,
} from "@/services/consultant.js";
/**
 * Typography component
 */
export default {
  page: {
    title: "Consultant Price",
    meta: [{ name: "Consultant_Price", content: appConfig.description }],
  },
  components: { Tables, Layout, PageHeader },
  data() {
    return {
      title: this.$t("contractPrices"),
      id: "",
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.consultant-prices.text"),
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],
      fields: [
        { label: this.$t("duration"), key: "duration", sortable: false },
        { label: this.$t("amount"), key: "amount", sortable: false },
        { label: this.$t("edit"), key: "edit", sortable: false },
      ],
      tableData: [],

      editData: { duration: "", amount: "" },
      AddData: { duration: "", amount: "" },
    };
  },
  methods: {
    editClicked(item) {
      console.log(item);
      const index = this.tableData.findIndex(
        (el) => el.duration === item.item.duration
      );
      console.log(index);
      this.editData.duration = this.tableData[index].duration;
      this.editData.amount = this.tableData[index].amount;
      this.id = this.tableData[index].id;

      this.$bvModal.show("modal-edit");
    },
    add() {
      AddPrice(this.AddData)
        .then(() => {
          this.$bvModal.hide("modal-add");
          this.successmsg("time Price added succesffully");
          this.loadPrices();
        })
        .catch((err) => {
          swal.fire({
            position: "center",
            icon: "error",
            title: `${err.response.data.msg}`,
          });
        });
    },
    edit() {
      EditPrice(this.id, this.editData).then(() => {
        this.$bvModal.hide("modal-edit");
        this.successmsg(" time Price edited succesffully");
        this.loadPrices();
      });
    },
    deletee() {
      swal
        .fire({
          position: "center",
          icon: "question",
          title: "Are you Sure Want To Delete This Item",
        })
        .then((res) => {
          if (res) {
            DeletePrice(this.id).then(() => {
              this.successmsg("Deleted Successfully");
              this.$bvModal.hide("modal-edit");
              this.loadPrices();
            });
          }
        });
    },
    successmsg(msg) {
      swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async loadPrices() {
      GetAllPrices().then((data) => {
        this.tableData = data;
        // console.log(data);
      });
    },
  },
  created() {
    this.loadPrices();
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <Tables
      :table-data="tableData"
      :fields="fields"
      :addbtn="true"
      :hideShowMore="true"
      @editClicked="editClicked"
    />
    <b-modal
      no-close-on-backdrop
      id="modal-edit"
      :title="'consultant price edit'"
      centered
      scrollable
      title-class="font-18"
    >
      <template #modal-footer>
        <div></div>
      </template>
      <form @submit.prevent="edit">
        <label for="duration">duration</label>
        <input
          id="duration"
          type="number"
          :placeholder="'please enter the duration'"
          v-model="editData.duration"
          class="form-control mb-4"
          required
        />
        <label for="amount">amount</label>
        <input
          id="amount"
          type="number"
          :placeholder="'please enter the amount'"
          v-model="editData.amount"
          class="form-control mb-4"
          required
        />
        <b-button
          style="margin-bottom: 10px"
          pill
          @click="deletee"
          variant="danger"
          >{{ $t("delete") }}</b-button
        >
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2"
            ><b-button @click="$bvModal.hide('modal-edit')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" type="submit">{{
              $t("ok")
            }}</b-button></b-col
          ></b-row
        >
      </form>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-add"
      :title="$t('add_contract_type')"
      centered
      scrollable
      title-class="font-18"
    >
      <template #modal-footer>
        <div></div>
      </template>
      <form @submit.prevent="add">
        <label for="duration">duration</label>
        <input
          id="duration"
          type="number"
          :placeholder="'please enter the duration'"
          v-model="AddData.duration"
          class="form-control mb-4"
          required
        />
        <label for="amount">amount</label>
        <input
          id="amount"
          type="number"
          :placeholder="'please enter the amount'"
          v-model="AddData.amount"
          class="form-control mb-4"
          required
        />
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2"
            ><b-button @click="$bvModal.hide('modal-add')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" type="submit">{{
              $t("ok")
            }}</b-button></b-col
          ></b-row
        >
      </form>
    </b-modal>
  </Layout>
</template>
